import React, { useState, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast, showSuccessToast } from "../../utils/Toaster";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { FaCircleInfo } from "react-icons/fa6";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { useNavigate } from "react-router-dom";
import { Tag } from "primereact/tag";
import { IoDocumentAttachOutline } from "react-icons/io5";
import SupervisorUploadPage from "../../Pages/DashBoard/SupervisorUploadPage";
import styles from "./DataTableSupervisor.module.css";
import { InputTextarea } from "primereact/inputtextarea";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import { InputNumber } from "primereact/inputnumber";
import { useMediaQuery } from "@mui/material";

// Headers for stages DataTableLines component
const DataTableSupervisor = ({
  lists,
  paginator,
  updateSuperVisorData,
  setPageNo,
  setOffset,
  pageNo,
  offset,
  pagination,
  fetchData,
}) => {
  // State variables
  const navigate = useNavigate();

  
  const [newList, setNewList] = useState([]);
  // const [expandedRows, setExpandedRows] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null); // State to store selected product ID
  // const [stages, setStages] = useState(stagesData);
  const [visible, setVisible] = useState(false);
  const [supervisor, setSupervisor] = useState(false);
  const [selectedProductionOrders, setselectedProductionOrders] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [showDialog, setShowDialog] = useState(false);
  const [showpaginator, setshowPaginator] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [stageId, setStageId] = useState(null);
  const [showHoldDialog, setHoldShowDialog] = useState(false);
  const [showResumeDialog, setResumeShowDialog] = useState(false);
  const [showCompleteDialog, setCompleteShowDialog] = useState(false);
  const [showReopenDialog, setReopenShowDialog] = useState(false);
  const [showHoldBulkDialog, setHoldShowBulkDialog] = useState(false);
  const [showResumeBulkDialog, setResumeShowBulkDialog] = useState(false);
  const [showCompleteBulkDialog, setCompleteShowBulkDialog] = useState(false);
  const [showReopenBulkDialog, setReopenShowBulkDialog] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [showDocsDialog, setshowDocsDialog] = useState(false);
  // const [supervisors, setSupervisors] = useState([]);
  const [lineStageStatus, setlineStageStatus] = useState(0);
  const [supervisorRemark, setSupervisorRemark] = useState("");
  const [usedQuantity, setUsedQuantity] = useState("");
  const [qtyerror, setqtyError] = useState("");
  const [remainingQuantity, setRemainingQuantity] = useState(0);
  const [first, setFirst] = useState(0);
  const [pageInput, setPageInput] = useState(pageNo);
  const [currentPage, setCurrentPage] = useState(pageNo);


  const userData = JSON.parse(localStorage?.getItem("userData"));

  useEffect(() => {
    // Redirect to login page if userData is not present or essential properties are missing
    if (!userData || !userData.userId || !userData.userName) {
      navigate("/login");
    }
  }, [navigate, userData]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onPageChange = (event) => {
    setFirst(event.first);
    setPageNo(event.page + 1);
    setOffset(event.rows);
  };
  const onNextPage = () => {
    const newPage = Number(currentPage) + 1;
    setPageNo(newPage);
        setCurrentPage(newPage);
  };

  const onPreviousPage = () => {
    const newPage = Number(currentPage) - 1;
    setPageNo(newPage);
    setCurrentPage(newPage);
  };

  const handleRowsChange = (event) => {
    setOffset(Number(event.target.value));
    setFirst(0);
    setPageNo(1);
  };


  const onLastPage = () => {
    const newPage = pagination.totalPages
    setPageNo(newPage);
    setCurrentPage(newPage);
    setFirst((newPage - 1) * offset);
  };

  const onFirstPage = () => {
    setPageNo(1);
    setCurrentPage(1);
    setFirst(0);
  };

  const handlePageInputChange = (event) => {
    const value = event.target.value;
    if (
      value === "" ||
      (Number(value) >= 1 && Number(value) <= pagination.totalPages)
    ) {
      setPageInput(value);
    }
  };

  const goToPage = (event) => {
    event.preventDefault();
    setPageNo(pageInput);
    setCurrentPage(pageInput);
    setFirst((pageInput - 1) * offset);
  };

  // Effect to update the list when the data changes
  useEffect(() => {
    if (lists) {
      // Add index property to each item for numbering
      setNewList(
        lists.map((item, index) => ({
          ...item,
          index: index + 1,
          tooltipTarget: `production-order-tooltip-${index}`,
          tooltipRemark: `tooltip-remark-${index}`,
        }))
      );
      setshowPaginator(paginator);
    }
  }, [lists]);

  // const onGlobalFilterChange = (event) => {
  //   const value = event.target.value;
  //   let _filters = { ...filters };
  //   _filters["global"].value = value;
  //   setFilters(_filters);
  // };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  
  const debouncedFilterChange = useCallback(
    debounce((value) => {
      fetchData(null, null, value);
    }, 800),
    []
    );

  const renderHeader = (options) => {
    const value = filters["global"] ? filters["global"].value : "";
    return (
      <div className="row d-flex justify-content-between align-items-center pl-2 pr-2 ">
        <div className=" text-left">
          <InputText
            type="search"
            value={value || ""}
            onChange={(event) => {
              const { value } = event.target;
              setPageNo(1);
              setFilters((prevFilters) => ({
                ...prevFilters,
                global: { value: value, matchMode: "contains" },
              }));
              if (value?.length > 1) {
                debouncedFilterChange(value);
              }
  
              if (value === "") {
                setNewList(
                  lists.map((item, index) => ({ ...item, index: index + 1 }))
                );
              }
            }}
            placeholder="Global Search"
          />
        </div>
        <div className="text-right">
          <>
            <button
              className="iconBtn hold"
              data-bs-toggle="modal"
              data-bs-target="#confirmModal"
              onClick={() => {
                setHoldShowBulkDialog(true);
                setlineStageStatus(2);
              }}
            >
              <svg
                width="8"
                height="10"
                viewBox="0 0 8 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1 0.5C0.447715 0.5 0 0.947715 0 1.5V8.5C0 9.05229 0.447715 9.5 1 9.5H2C2.55228 9.5 3 9.05229 3 8.5V1.5C3 0.947715 2.55228 0.5 2 0.5H1ZM6 0.5C5.44772 0.5 5 0.947715 5 1.5V8.5C5 9.05229 5.44772 9.5 6 9.5H7C7.55228 9.5 8 9.05229 8 8.5V1.5C8 0.947715 7.55228 0.5 7 0.5H6Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              className="iconBtn complete"
              data-bs-toggle="modal"
              data-bs-target="#confirmModal"
              onClick={() => {
                setCompleteShowBulkDialog(true);
                setlineStageStatus(4);
              }}
            >
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4L4.5 7.5L10.5 1.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        </div>
      </div>
    );
  };

  const handleStageStatusBulk = async () => {
    if (!selectedRows) {
      setReopenShowBulkDialog(false);
      setHoldShowBulkDialog(false);
      setCompleteShowBulkDialog(false);
      setResumeShowBulkDialog(false);
      showErrorToast("Please select atleast one row");
      return;
    }
    if (!supervisorRemark?.length && lineStageStatus !== 4) {
      showErrorToast("Please enter remark");
      setReopenShowBulkDialog(false);
      setHoldShowBulkDialog(false);
      setCompleteShowBulkDialog(false);
      setResumeShowBulkDialog(false);
      return;
    }

    try {
      const response = await axiosPrivate.put(
        `tracking_stages/update_status/${lineStageStatus}`,
        {
          line_stage_ids: selectedRows.map((line) => line.line_stage_id),
          remarks:
            lineStageStatus === 2
              ? `${supervisorRemark}: ${
                  userData?.userName
                }\n[${new Date().toLocaleString("en-GB")}]\n`
              : "",
        }
      );
      if (response?.status === 200) {
        showSuccessToast("Stage Status Updated Successfully");
        updateSuperVisorData();
      }
    } catch (err) {
      showErrorToast(err?.response?.data?.message);
    } finally {
      setReopenShowBulkDialog(false);
      setHoldShowBulkDialog(false);
      setCompleteShowBulkDialog(false);
      setResumeShowBulkDialog(false);
      setSupervisorRemark("");
      setSelectedRows(null);
    }
  };

  const handleResumeStage = async () => {
    if (!supervisorRemark?.length) {
      showErrorToast("Please enter remark");
      return;
    }
    try {
      const response = await axiosPrivate.put(
        `tracking_stages/update_status/${lineStageStatus}`,
        {
          line_stage_ids: [stageId],
          remarks:
            lineStageStatus === 1
              ? `${supervisorRemark}: ${
                  userData?.userName
                }\n[${new Date().toLocaleString("en-GB")}]\n`
              : "",
        }
      );

      if (response.status === 200) {
        showSuccessToast("Stage Status Updated Successfully");
        updateSuperVisorData();
      }
    } catch (error) {
      showErrorToast(error.response.data.message);
    } finally {
      setReopenShowDialog(false);
      setHoldShowDialog(false);
      setCompleteShowDialog(false);
      setResumeShowDialog(false);
      setSupervisorRemark("");
      setlineStageStatus(null);
    }
  };

  const handleStageUpdate = async (usedQuantity) => {
    let reopenRes;
    let response;
    if (!supervisorRemark?.length && lineStageStatus !== 4) {
      showErrorToast("Please enter remark");
      setReopenShowBulkDialog(false);
      setHoldShowBulkDialog(false);
      setCompleteShowBulkDialog(false);
      setResumeShowBulkDialog(false);
      return;
    }
    try {
      if (lineStageStatus === 1) {
        reopenRes = await axiosPrivate.put(
          `tracking_stages/create_reopen_request/${stageId}`,
          {
            remarks: `${supervisorRemark}: ${
              userData?.userName
            }\n[${new Date().toLocaleString("en-GB")}]\n`,
          }
        );
      } else {
        response = await axiosPrivate.put(
          `tracking_stages/update_status/${lineStageStatus}`,
          {
            line_stage_ids: [stageId],
            remarks:
              lineStageStatus === 2
                ? `${supervisorRemark}: ${
                    userData?.userName
                  }\n[${new Date().toLocaleString("en-GB")}]\n`
                : "",
            moved_qty: lineStageStatus === 2 ? 0 : usedQuantity,
          }
        );
      }
      if (response?.status === 200 || reopenRes?.status === 200) {
        showSuccessToast("Stage Status Updated Successfully");
        updateSuperVisorData();
      }
    } catch (err) {
      showErrorToast(err?.response?.data?.message);
    } finally {
      setReopenShowDialog(false);
      setHoldShowDialog(false);
      setCompleteShowDialog(false);
      setResumeShowDialog(false);
      setSupervisorRemark("");
      setlineStageStatus(null);
      setUsedQuantity("");
      setSelectedRows(null);
      setqtyError("");
      setRemainingQuantity(0);
    }
  };

  const actionTemplate = (rowData) => {
    if (rowData.reopen_request) return <span>Requested!</span>;

    return rowData.status === 1 ? ( // status -> in progress
      <>
        <button
          className="iconBtn hold"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          onClick={() => {
            setHoldShowDialog(true);
            setStageId(rowData?.line_stage_id);
            setlineStageStatus(2);
          }}
        >
          <svg
            width="8"
            height="10"
            viewBox="0 0 8 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1 0.5C0.447715 0.5 0 0.947715 0 1.5V8.5C0 9.05229 0.447715 9.5 1 9.5H2C2.55228 9.5 3 9.05229 3 8.5V1.5C3 0.947715 2.55228 0.5 2 0.5H1ZM6 0.5C5.44772 0.5 5 0.947715 5 1.5V8.5C5 9.05229 5.44772 9.5 6 9.5H7C7.55228 9.5 8 9.05229 8 8.5V1.5C8 0.947715 7.55228 0.5 7 0.5H6Z"
              fill="white"
            />
          </svg>
        </button>
        <button
          className="iconBtn complete"
          data-bs-toggle="modal"
          data-bs-target="#confirmModal"
          onClick={() => {
            setCompleteShowDialog(true);
            setStageId(rowData?.line_stage_id);
            setlineStageStatus(4);
            setRemainingQuantity(
              rowData?.remaining_qty ? rowData?.remaining_qty : 0
            );
          }}
        >
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 4L4.5 7.5L10.5 1.5"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </>
    ) : rowData.status === 4 ? ( //status -> complete
      <button
        className="iconBtn reopen"
        data-bs-toggle="modal"
        data-bs-target="#confirmModal"
        onClick={() => {
          setReopenShowDialog(true);
          setStageId(rowData?.line_stage_id);
          setlineStageStatus(1);
        }}
      >
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 5.68743C0 6.13688 0.363086 6.5 0.8125 6.5C1.26191 6.5 1.625 6.13688 1.625 5.68743C1.625 4.34161 2.7168 3.24972 4.0625 3.24972H8.125V4.06229C8.125 4.38986 8.32305 4.68695 8.62773 4.81392C8.93242 4.94088 9.28027 4.86978 9.51387 4.63871L11.1389 3.01356C11.4563 2.69615 11.4563 2.18068 11.1389 1.86327L9.51387 0.238128C9.28027 0.00451427 8.93242 -0.0640464 8.62773 0.0629178C8.32305 0.189882 8.125 0.484439 8.125 0.812006V1.62458H4.0625C1.81797 1.62458 0 3.4427 0 5.68743ZM13 7.31257C13 6.86312 12.6369 6.5 12.1875 6.5C11.7381 6.5 11.375 6.86312 11.375 7.31257C11.375 8.65839 10.2832 9.75028 8.9375 9.75028H4.875V8.93771C4.875 8.61014 4.67695 8.31305 4.37227 8.18608C4.06758 8.05912 3.71973 8.13022 3.48613 8.36129L1.86113 9.98643C1.54375 10.3038 1.54375 10.8193 1.86113 11.1367L3.48613 12.7619C3.71973 12.9955 4.06758 13.064 4.37227 12.9371C4.67695 12.8101 4.875 12.5156 4.875 12.1855V11.3754H8.9375C11.182 11.3754 13 9.5573 13 7.31257Z"
            fill="white"
          />
        </svg>
      </button>
    ) : rowData.status === 2 ? ( //status -> hold
      <button
        className="iconBtn hold"
        data-bs-toggle="modal"
        data-bs-target="#confirmModal"
        onClick={() => {
          setlineStageStatus(1);
          setResumeShowDialog(true);
          setStageId(rowData?.line_stage_id);
        }}
      >
        <svg
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.10303 4.70089C10.103 5.27824 10.103 6.72161 9.10303 7.29896L2.5736 11.0687C1.5736 11.6461 0.3236 10.9244 0.3236 9.76969L0.323601 2.23016C0.323601 1.07546 1.5736 0.353769 2.5736 0.931119L9.10303 4.70089Z"
            fill="white"
          />
        </svg>
      </button>
    ) : (
      // status ->pending
      <span className="iconBtn start">
        <svg
          width="15"
          height="3"
          viewBox="0 0 15 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="white" />
          <circle cx="7.5" cy="1.5" r="1.5" fill="white" />
          <circle cx="13.5" cy="1.5" r="1.5" fill="white" />
        </svg>
      </span>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={
          rowData?.status === 0
            ? "Pending"
            : rowData?.status === 1
            ? "In Progress"
            : rowData?.status === 2
            ? "Hold"
            : rowData?.status === 3
            ? "Resume"
            : rowData?.status === 4
            ? "Completed"
            : rowData?.status === 5
            ? "Re-open"
            : "Pending"
        }
        severity={
          rowData?.status === 0
            ? "warning"
            : rowData?.status === 1
            ? "info"
            : rowData?.status === 2
            ? "danger"
            : rowData?.status === 3
            ? "info"
            : rowData?.status === 4
            ? "success"
            : rowData?.status === 5
            ? "info"
            : "primary"
        }
      />
    );
  };

  const header = renderHeader();

  const renderProductionOrderBody = (rowData) => (
    <>
      <span id={rowData?.tooltipTarget} className="p-overlay-badge">
        {rowData?.prod_order}
        <FaCircleInfo className="ml-3 mb-1" style={{ cursor: "pointer" }} />
      </span>
      <Tooltip
        target={`#${rowData.tooltipTarget}`}
        position="right"
        content={rowData?.LineDesc}
      />
    </>
  );

  const onHideHoldDialog = () => {
    setHoldShowDialog(false);
    setStageId(null);
    setSupervisorRemark("");
  };
  const onHideResumeDialog = () => {
    setResumeShowDialog(false);
    setStageId(null);
    setlineStageStatus(null);
    setSupervisorRemark("");
  };
  const onHideCompleteDialog = () => {
    setCompleteShowDialog(false);
    setStageId(null);
    setSupervisorRemark("");
    setRemainingQuantity(0);
    setqtyError("");
    setUsedQuantity("");
  };
  const onHideReopenDialog = () => {
    setReopenShowDialog(false);
    setCompleteShowDialog(false);
    setStageId(null);
    setSupervisorRemark("");
  };
  const onHideHoldBulkDialog = () => {
    setHoldShowBulkDialog(false);
    setStageId(null);
    setSupervisorRemark("");
  };
  const onHideResumeBulkDialog = () => {
    setResumeShowBulkDialog(false);
    setStageId(null);
    setlineStageStatus(null);
    setSupervisorRemark("");
  };
  const onHideCompleteBulkDialog = () => {
    setCompleteShowBulkDialog(false);
    setStageId(null);
    setSupervisorRemark("");
  };
  const onHideReopenBulkDialog = () => {
    setReopenShowBulkDialog(false);
    setStageId(null);
    setSupervisorRemark("");
  };

  const remarksBodyTemplate = (rowData) => {
    return rowData?.remarks ? (
      <>
        <span id={rowData?.tooltipRemark} className="p-overlay-badge">
          {rowData?.remarks ? rowData?.remarks.slice(0, 25) + "..." : "-"}
          <FaCircleInfo className="ml-1" style={{ cursor: "pointer" }} />
        </span>
        <Tooltip
          target={`#${rowData.tooltipRemark}`}
          mouseTrack
          mouseTrackLeft={10}
          content={rowData?.remarks}
        />
      </>
    ) : (
      <span className="pl-4">-</span>
    );
  };

  const flagsBodyTemplate = (rowData) => {
    const flagsData =
      typeof rowData?.flags === "string"
        ? JSON.parse(rowData?.flags)
        : rowData?.flags;

    return (
      <div>
        {rowData?.flags && Array.isArray(flagsData)
          ? flagsData?.map((flag, index) => (
              <Tag
                key={index}
                value={flag?.label}
                style={{ fontSize: "8px", padding: "1px" }}
                className="p-mr-1 p-text-sm m-1"
                severity={
                  flag?.label === "Overdue"
                    ? "danger"
                    : flag?.label === "Urgent"
                    ? "warning"
                    : flag?.label === "Low"
                    ? "info"
                    : "success"
                }
              />
            ))
          : "-"}
      </div>
    );
  };

  const PlannedEndTemplate = (rowData) => {
    return (
      <span>
        {rowData?.planned_end
          ? new Date(rowData.planned_end).toLocaleDateString("en-GB")
          : "-"}
      </span>
    );
  };
  const holdReasons = [
    { label: "Machine Breakdown", value: "Machine Breakdown" },
    { label: "Material Shortage", value: "Material Shortage" },
    { label: "Previous Stage Delay", value: "Previous Stage Delay" },
  ];

  const reopenReasons = [
    { label: "Reason A", value: "Reason A" },
    { label: "Reason B", value: "Material Shortage" },
    { label: "Reason C", value: "Reason C" },
  ];

  const ResumeReasons = [
    { label: "Machine Repaired", value: "Machine Repaired" },
    { label: "Material Available", value: "Material Available" },
    { label: "Previous Stage Completed", value: "Previous Stage Completed" },
  ];

  const docsButtonTemplate = (rowData) => {
    if (rowData?.documents?.length === 0) {
      return null;
    }
    const newDocs = rowData?.documents
      ? rowData.documents.map((doc) => ({
          ...doc,
          type:
            doc.document.includes(".jpg") ||
            doc.document.includes(".png") ||
            doc.document.includes(".jpeg")
              ? "image"
              : "file",
        }))
      : [];

    return (
      <IoDocumentAttachOutline
        size={30}
        color="#007BFF"
        onClick={() => {
          setshowDocsDialog(true);
          setDocuments(newDocs);
        }}
        style={{
          cursor: "pointer",
          padding: "5px",
          backgroundColor: "initial",
          borderRadius: "5px",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "initial")}
      />
    );
  };

  const handleUsedQuantityChange = (e) => {
    const value = e.target.value;
    if (value > remainingQuantity) {
      setqtyError("Moved quantity cannot be greater than remaining quantity !");
    } else {
      setqtyError("");
      setUsedQuantity(value);
    }
  };

  const handleConfirm = () => {
    if (!usedQuantity) {
      setqtyError("Please enter quantity!");
      return;
    }
    if (usedQuantity <= remainingQuantity && qtyerror === "" && usedQuantity) {
      handleStageUpdate(usedQuantity); // Pass the used quantity to the update function
    } else {
      setqtyError("Moved quantity cannot be greater than remaining quantity!");
    }
  };

  return (
    <>
      {/* Upload Module Dialog */}
      <Dialog
        header="Uploads"
        visible={showDocsDialog}
        style={{ minWidth: "80vw" }}
        onHide={() => setshowDocsDialog(false)}
      >
        <SupervisorUploadPage documents={documents} />
      </Dialog>

      {/* Complete Stage Dialog */}
      <Dialog
        header="Confirmation"
        visible={showCompleteDialog}
        style={{ width: "30vw" }}
        onHide={onHideCompleteDialog}
      >
        <div className="p-d-flex p-ai-center p-dir-col">
          <p>Are you sure you want to end this stage!</p>
          <p>क्या आप इस चरण को समाप्त करना चाहते है?</p>
          <div className="d-flex justify-content-between">
            <div className="p-d-flex p-ai-center">
              <label htmlFor="usedQuantity">Moved Quantity: </label>{" "}
              <input
                type="number"
                id="usedQuantity"
                value={usedQuantity}
                onChange={handleUsedQuantityChange}
                min="0"
                max={remainingQuantity}
              />
            </div>
            <div className="p-d-flex p-ai-center">
              <p>Remaining Quantity: {remainingQuantity}</p>
            </div>
          </div>
          {qtyerror && <div className="p-error mb-3">{qtyerror}</div>}
          <div className="d-flex justify-content-start mt-3">
            <button className="btn btn-primary" onClick={handleConfirm}>
              Yes
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={onHideCompleteDialog}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>

      {/* Complete Bulk Stages Dialog */}
      <Dialog
        header="Confirmation"
        visible={showCompleteBulkDialog}
        style={{ width: "30vw" }}
        onHide={onHideCompleteBulkDialog}
      >
        <div className="p-d-flex p-ai-center p-dir-col">
          <p>Are you sure you want to end all selected stages!</p>
          <p>क्या आप इस चरण को समाप्त करना चाहते है?</p>
          <div className="p-d-flex p-ai-center">
            <button className="btn btn-primary" onClick={handleStageStatusBulk}>
              Yes
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={onHideCompleteBulkDialog}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>

      {/* Reopen Stage Dialog */}
      <Dialog
        header="Confirmation"
        visible={showReopenDialog}
        style={{ width: "30vw" }}
        onHide={onHideReopenDialog}
      >
        <div className="p-d-flex p-ai-center p-dir-col">
          <p>Are you sure you want to reopen this stage!</p>
          <p>क्या आप इस चरण को फिर से शुरू करना चाहते है?</p>
          <div className="col-md-12 col-sm-12">
            <div className="form-group">
              <label className="form-label">Select Reason</label>
              <Dropdown
                name="reason"
                options={reopenReasons}
                value={supervisorRemark ? supervisorRemark : ""}
                onChange={(e) => setSupervisorRemark(e.value)}
                placeholder="Select Reason"
                optionLabel="label"
                optionValue="value"
                filter
                showClear={supervisorRemark?.length} // Conditionally render clear icon
                filterBy="label"
                className="w-100"
              />
              {!supervisorRemark?.length && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select reason!
                </div>
              )}
            </div>
            <div className="form-group">
              <label className="form-label">Write your Own Remark</label>
              <InputTextarea
                rows={3}
                cols={30}
                value={supervisorRemark}
                onChange={(e) => {
                  setSupervisorRemark(e.target.value);
                }}
              />
              {!supervisorRemark?.length && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select reason!
                </div>
              )}
            </div>
          </div>
          <div className="p-d-flex p-ai-center">
            <button className="btn btn-primary" onClick={handleStageUpdate}>
              Yes
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={onHideReopenDialog}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>

      {/* Reopen Bulk stages Dialog */}
      <Dialog
        header="Confirmation"
        visible={showReopenBulkDialog}
        style={{ width: "30vw" }}
        onHide={onHideReopenBulkDialog}
      >
        <div className="p-d-flex p-ai-center p-dir-col">
          <p>Are you sure you want to reopen this stage!</p>
          <p>क्या आप इस चरण को फिर से शुरू करना चाहते है?</p>
          <div className="col-md-12 col-sm-12">
            <div className="form-group">
              <label className="form-label">Select Reason</label>
              <Dropdown
                name="reason"
                options={holdReasons}
                value={supervisorRemark ? supervisorRemark : ""}
                onChange={(e) => setSupervisorRemark(e.value)}
                placeholder="Select Reason"
                optionLabel="label"
                optionValue="value"
                filter
                showClear={supervisorRemark?.length} // Conditionally render clear icon
                filterBy="label"
                className="w-100"
              />
              {!supervisorRemark?.length && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select reason!
                </div>
              )}
            </div>
          </div>
          <div className="p-d-flex p-ai-center">
            <button className="btn btn-primary" onClick={handleStageStatusBulk}>
              Yes
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={onHideReopenBulkDialog}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>

      {/* Hold stage Dialog */}
      <Dialog
        header="Confirmation"
        visible={showHoldDialog}
        style={{ width: "30vw" }}
        onHide={onHideHoldDialog}
      >
        <div className="p-d-flex p-ai-center p-dir-col">
          <p>Are you sure you want to hold this stage!</p>
          <p>क्या आप इस चरण को रोकना चाहते हैं?</p>
          <div className="col-md-12 col-sm-12">
            <div className="form-group">
              <label className="form-label">Select Reason</label>
              <Dropdown
                name="reason"
                options={holdReasons}
                value={supervisorRemark ? supervisorRemark : ""}
                onChange={(e) => setSupervisorRemark(e.value)}
                placeholder="Select Reason"
                optionLabel="label"
                optionValue="value"
                filter
                showClear={supervisorRemark?.length} // Conditionally render clear icon
                filterBy="label"
                className="w-100"
              />
              {!supervisorRemark?.length && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select reason!
                </div>
              )}
            </div>
            <div className="form-group">
              <label className="form-label">Write your Own Remark</label>
              <InputTextarea
                rows={3}
                cols={30}
                value={supervisorRemark}
                onChange={(e) => {
                  setSupervisorRemark(e.target.value);
                }}
              />
              {!supervisorRemark?.length && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select reason!
                </div>
              )}
            </div>
          </div>
          <div className="p-d-flex p-ai-center">
            <button className="btn btn-primary" onClick={handleStageUpdate}>
              Yes
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={onHideHoldDialog}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>

      {/* Hold Bulk stage Dialog  */}
      <Dialog
        header="Confirmation"
        visible={showHoldBulkDialog}
        style={{ width: "30vw" }}
        onHide={onHideHoldBulkDialog}
      >
        <div className="p-d-flex p-ai-center p-dir-col">
          <p>Are you sure you want to hold this stage!</p>
          <p>क्या आप इस चरण को रोकना चाहते हैं?</p>
          <div className="col-md-12 col-sm-12">
            <div className="form-group">
              <label className="form-label">Select Reason</label>
              <Dropdown
                name="reason"
                options={holdReasons}
                value={supervisorRemark ? supervisorRemark : ""}
                onChange={(e) => setSupervisorRemark(e.value)}
                placeholder="Select Reason"
                optionLabel="label"
                optionValue="value"
                filter
                showClear={supervisorRemark?.length} // Conditionally render clear icon
                filterBy="label"
                className="w-100"
              />
              {!supervisorRemark?.length && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select reason!
                </div>
              )}
            </div>
          </div>
          <div className="p-d-flex p-ai-center">
            <button className="btn btn-primary" onClick={handleStageStatusBulk}>
              Yes
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={onHideHoldBulkDialog}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>

      {/* Resume Stage Dialog */}
      <Dialog
        header="Confirmation"
        visible={showResumeDialog}
        style={{ width: "30vw" }}
        onHide={onHideResumeDialog}
      >
        <div className="p-d-flex p-ai-center p-dir-col">
          <p>Are you sure you want to resume this stage!</p>
          <p>क्या आप इस चरण को शुरू करना चाहते है?</p>

          <div className="col-md-12 col-sm-12">
            <div className="form-group">
              <label className="form-label">Select Reason</label>
              <Dropdown
                name="reason"
                options={ResumeReasons}
                value={supervisorRemark ? supervisorRemark : ""}
                onChange={(e) => setSupervisorRemark(e.value)}
                placeholder="Select Reason"
                optionLabel="label"
                optionValue="value"
                filter
                showClear={supervisorRemark?.length} // Conditionally render clear icon
                filterBy="label"
                className="w-100"
              />
              {!supervisorRemark?.length && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select reason!
                </div>
              )}
            </div>
            <div className="form-group">
              <label className="form-label">Write your Own Remark</label>
              <InputTextarea
                rows={3}
                cols={30}
                value={supervisorRemark}
                onChange={(e) => {
                  setSupervisorRemark(e.target.value);
                }}
              />
              {!supervisorRemark?.length && (
                <div
                  style={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  Please select reason!
                </div>
              )}
            </div>
          </div>

          <div className="p-d-flex p-ai-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                handleResumeStage();
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={onHideResumeDialog}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Confirmation"
        visible={showResumeBulkDialog}
        style={{ width: "30vw" }}
        onHide={onHideResumeDialog}
      >
        <div className="p-d-flex p-ai-center p-dir-col">
          <p>Are you sure you want to resume this stage!</p>
          <p>क्या आप इस चरण को शुरू करना चाहते है?</p>

          <div className="p-d-flex p-ai-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                setlineStageStatus(1);
                handleStageStatusBulk();
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={onHideResumeBulkDialog}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>

      <DataTable
        value={newList}
        header={header}
        className="p-2"
        // expandedRows={expandedRows}
        // onRowToggle={(e) => {
        //   setExpandedRows(e.data);
        // }}
        // rowExpansionTemplate={rowExpansionTemplate}
        // paginator
        rows={20}
        size="small"
        rowClassName={(rowData) => {
          return rowData.reopen_request ? `${styles.requested_stage}` : "";
        }}
        paginator={showpaginator}
        onPage={onPageChange}
        rowsPerPageOptions={showpaginator ? [20, 40, 60, 80] : false}
        paginatorTemplate={
          showpaginator
            ? "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            : false
        }
        currentPageReportTemplate={
          showpaginator ? "{first} to {last} of {totalRecords}" : false
        }
        scrollable={!showpaginator}
        scrollHeight={showpaginator ? false : "400px"}
        selectionMode="checkbox"
        selection={selectedRows}
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        onSelectionChange={(e) =>
          setSelectedRows(e.value.filter((rowData) => !rowData.reopen_request))
        }
        dataKey="line_stage_id"
        tableStyle={{ minWidth: "1700px" }}
      >
        {/* Columns definition */}
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "3rem" }}
        ></Column>

        <Column
          field="stage_name"
          header="Stage"
          headerStyle={{ minWidth: "180px" }}
          sortable={true}
        />

        <Column
          field="CustomerName"
          header="Customer Name"
          headerStyle={{ minWidth: "260px" }}
          sortable={true}
        />

        <Column
          field="OrderNum"
          header="Sales Order"
          // headerStyle={{ width: "15%", textAlign: "left" }}
          sortable={true}
        />

        <Column
          field="prod_order"
          header="Production Order"
          headerStyle={{ width: "10%", textAlign: "left" }}
          body={renderProductionOrderBody}
          sortable={true}
        />

        <Column
          field="OrderQty"
          header="Order Qty."
          // headerStyle={{ width: "10%", textAlign: "left" }}
          sortable={true}
        />
        <Column
          field="remaining_qty"
          header="Remaining Qty."
          body={(rowData) =>
            rowData?.remaining_qty ? rowData?.remaining_qty : "0"
          }
          sortable={true}
        />
        <Column
          field="OrderLine"
          header="Order Line"
          body={(rowData) => (rowData?.OrderLine ? rowData?.OrderLine : "-")}
          sortable={true}
        />
        <Column field="ProdGroup" header="Product Group" sortable={true} />
        <Column
          field="planned_end"
          header="Deadline"
          body={PlannedEndTemplate}
          // headerStyle={{ width: "8%", textAlign: "left" }}
          // bodyStyle={{ textAlign: "left" }}
          sortable
        />

        <Column
          field="new_status"
          header="Status"
          // headerStyle={{ width: "5%", textAlign: "right", paddingLeft: "10px" }}
          // bodyStyle={{ textAlign: "center" }}
          body={statusBodyTemplate}
          // sortable
        />
        <Column
          field="new_flags"
          header="Flags"
          // headerStyle={{ width: "12%" }}
          body={flagsBodyTemplate}
        />

        <Column
          field="new_remarks"
          header="Remarks"
          headerStyle={{ minWidth: "260px" }}
          body={remarksBodyTemplate}
          // bodyStyle={{ paddingLeft: "20px" }}
          // headerStyle={{ width: "15%", paddingLeft: "20px" }}
        />

        <Column body={docsButtonTemplate} headerStyle={{ width: "1%" }} />

        <Column field="action" header="Action" body={actionTemplate}   headerStyle={{ minWidth: "150px" }}/>
      </DataTable>

      <>
        <div
          style={{
            margin: "10px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={onFirstPage}
            disabled={currentPage === 1}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
          >
            <FaAngleDoubleLeft size={20} />
          </button>
          <button
            onClick={onPreviousPage}
            disabled={currentPage === 1}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
          >
            <FaAngleLeft size={20} />
          </button>
          <span style={{ margin: "0 10px" }}>
            Showing Page {currentPage} of {pagination.totalPages}{" "}
            from total {pagination.totalRecords} entries
          </span>
          <button
            onClick={onNextPage}
            disabled={currentPage === pagination.totalPages}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
          >
            <FaAngleRight size={20} />
          </button>
          <button
            onClick={onLastPage}
            disabled={currentPage === pagination.totalPages}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
          >
            <FaAngleDoubleRight size={20} />
          </button>
          <select
            value={offset}
            disabled={currentPage ===  pagination.totalPages}
            onChange={handleRowsChange}
            style={{ marginLeft: "10px" }}
          >
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={80}>80</option>
          </select>
        </div>
        <form
          onSubmit={goToPage}
          style={{ marginLeft: "15px" }}
          className="d-flex justify-content-end align-items-center mr-3"
        >
          <span>Go to Page : </span>
          <input
            type="number"
            value={pageInput}
            onChange={handlePageInputChange}
            min={1}
            max={pagination.totalPages}
            style={{
              width: "40px",
              textAlign: "center",
              marginLeft: "10px",
            }}
          />
          <button
            type="submit"
            className="btn btn-primary"
            style={{ marginLeft: "15px", padding: "3px 8px " }}
          >
            Go
          </button>
        </form>
      </>
    </>
  );
};

export default DataTableSupervisor;
