import React, { useEffect, useState } from "react";
import { FcClearFilters } from "react-icons/fc";
import { TabView, TabPanel } from "primereact/tabview";
import DataTablePrime from "../../components/DataTablePrime/DataTablePrime";
import Select from "react-select";
import { Calendar } from "primereact/calendar";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { showErrorToast } from "../../utils/Toaster";
import axiosPrivate from "../../hooks/axiosPrivate";
import Loader from "../../context/Loader/Loader";
import { Link } from "react-router-dom";
import { GrFormView } from "react-icons/gr";
import DataTableLines from "../../components/DataTableLines/DataTableLines";
import DataTableRequests from "../../components/DataTableRequests/DataTableRequests";
import { useSearchParams } from "react-router-dom";

const headers = [
  { name: "Customer Name", field: "CustomerName", sortable: true },
  { name: "Job No.", field: "OrderNum", sortable: true },
  { name: "Order Type", field: "OrderType", sortable: true },
  { name: "Amount", field: "IndianAmt", sortable: true },
  { name: "Order Date", field: "OrderDt", sortable: true },
  { name: "Release Date", field: "ProductionRelDt", sortable: true },
  { name: "ACTION", field: "action" },
];

const JobsList = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [activeTabIndex, setActiveTabIndex] = useState(() =>
    searchParams.get("activeTab") ? searchParams.get("activeTab") : 0
  );
  const [filterOrderType, setFilterOrderType] = useState([]);
  const [filterOrderNum, setFilterOrderNum] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterOrderDate, setFilterOrderDate] = useState(null);
  const [jobsData, setJobsData] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  const [linesData, setLinesData] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [requestsData, setRequestsData] = useState([]);
  const [pageNo, setPageNo] = useState(() => searchParams.get("page") > 0 ? searchParams.get("page") : 1);
  const [linesPageNo, setLinesPageNo] = useState(() =>
    searchParams.get("linePage") > 0 ? searchParams.get("linePage") : 1
  );
  const [filtersActive, setFiltersActive] = useState(false);



  const [offset, setOffset] = useState(20);
  const [linesOffset, setLinesOffset] = useState(20);
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalLines, setTotalLines] = useState(0);
  const [databyProdGroup, setDatabyProdGroup] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [runningStagesDialog, setRunningStagesDialog] = useState(false);
  const [updateReleaseDateDialog, setUpdateReleaseDateDialog] = useState(false);
  const [supervisorId, setSupervisorId] = useState(null);
  const [jobStatusDialog, setJobStatusDialog] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [jobStatus, setJobStatus] = useState(null);
  const [orderTypes, setOrderTypes] = useState([]);
  const [salesOrders, setSalesOrders] = useState([]);
  const [productionOrder, setProductionOrder] = useState(null);
  const [supervisorIdLines, setSupervisorIdLines] = useState(null);
  const [runningStages, setRunningStages] = useState(null);
  const [releaseDate, setReleaseDate] = useState(null);
  const [lineStatus, setLineStatus] = useState(null);
  const [pagination, setPagination] = useState(0);
  const [linesPagination, setLinesPagination] = useState(0);
  // const [keyword, setKeyword] = useState("");

  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  // const statusOptions = [
  //   ...new Set(filteredJobs?.map((line) => line.job_status)),
  // ];

  const statusOptions = [0, 1, 4];

  const fetchJobsData = async (value , clearFilters) => {
    // console.log("Fetching jobs data", value);
    if(value ){
      const params = {
        page: pageNo,
        linePage: linesPageNo,
        activeTab: activeTabIndex,
      };
      if (value?.length) {
        params.keyword = value;
      }
      else {
        params.keyword = "";
        searchParams.delete(params.keyword);
      }
      
      setSearchParams(params);
  
    }
   
    const payload = {
      order_type: clearFilters ? null : filterOrderType,
      order_nums: clearFilters ? null : filterOrderNum,
      status: clearFilters ? null : filterStatus,
      order_date: clearFilters ? null : filterOrderDate
      ? new Date(filterOrderDate).toLocaleDateString("en-GB")
      : null,
      prod_grp: '',
      type: "jobs",
      keyword: value,
    }
    try {
      setIsLoading(true);
      const response = await axiosPrivate.post(`job_list?page=${pageNo}&offset=${offset}`, payload);
      if (response.status === 200) {
        const Data = response?.data?.data || [];
        setTotalJobs(response?.data?.count[0]?.total_jobs);
        setTotalLines(response?.data?.count[0]?.total_lines);
        setOrderTypes(response?.data?.order_types);
        setSalesOrders(response?.data?.sales_orders);
        setPagination(response?.data?.pagination);
        console.log(response?.data?.pagination);


        // const orderNumMap = new Map();
        // const SoAmountMap = new Map();
        // const ProdGroupMap = new Map();

        // Data.forEach((line) => {
        //   if (SoAmountMap.has(line.OrderNum)) {
        //     SoAmountMap.set(
        //       line?.OrderNum,
        //       SoAmountMap.get(line?.OrderNum) +
        //       Number(line?.IndianAmt)
        //     );
        //   } else {
        //     SoAmountMap.set(
        //       line?.OrderNum,
        //       Number(line?.IndianAmt)
        //     );
        //   }
        // });


        // Data?.forEach(line => {
        //   if (ProdGroupMap.has(line?.OrderNum)) {
        //     if (!ProdGroupMap.get(line?.OrderNum)?.includes(line.ProdGroup)) {
        //       ProdGroupMap.set(line?.OrderNum, ProdGroupMap.get(line?.OrderNum) + `\n ${line?.ProdGroup}`);
        //     }
        //   } else {
        //     ProdGroupMap.set(line?.OrderNum, line?.ProdGroup)
        //   }
        // })

        // Data?.forEach((line) => {
        //   if (!orderNumMap.has(line.OrderNum)) {
        //     orderNumMap.set(line.OrderNum, {
        //       ...line,
        //       // SOTotalAmount: SoAmountMap.get(line.OrderNum)
        //       //   ? SoAmountMap.get(line.OrderNum)
        //       //   : "-",
        //       ProdGroupList: ProdGroupMap.get(line.OrderNum)
        //         ? ProdGroupMap.get(line.OrderNum) : '-'

        //     });
        //   }
        // });
      
        setJobsData(
          Data.sort(
            (a, b) => b.held_stages - a.held_stages
          )
        );
        setFilteredJobs(
          Data.sort(
            (a, b) => b.held_stages - a.held_stages
          )
        );
        // if (
        //   arg &&
        //   (filterOrderType?.length ||
        //     filterOrderNum?.length ||
        //     filterStatus?.length ||
        //     filterOrderDate)
        // ) {
        //   applyFilters();
        // }
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
        console.log(error);
      }  else{
        setJobsData([]);
        setFilteredJobs([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLinesData = async (value, prod_group , tabchange) => {
    const payload = {
      order_types: filterOrderType,
      order_nums: filterOrderNum,
      status: filterStatus,
      order_date: filterOrderDate
      ? new Date(filterOrderDate).toLocaleDateString("en-GB")
      : null,
      prod_grp:  prod_group || searchParams.get('prodGroup'),
      type: "lines",
      keyword: value ? value : null,
    }
    try {
      console.log("------->",value);
      setIsLoading(true);
      const response = await axiosPrivate.post(`job_list?page=${tabchange ? 1 :linesPageNo}&offset=${linesOffset}`, payload);
      if (response.status === 200) {
        const Data = response?.data?.data || [];
        setLinesData(Data);
        setProductCategories(response?.data?.product_groups);
        setLinesPagination(response?.data?.pagination);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
      else{
        setLinesData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateJobsList = () => {
    fetchJobsData(null);
    
  };





  useEffect(() => {
    const params = {
      page: pageNo,
      linePage: linesPageNo,
      activeTab: activeTabIndex,
    };
    if (searchParams.get("prodGroup")) {
      params.prodGroup = searchParams.get("prodGroup");
    }
    setSearchParams(params);
  }, [pageNo, linesPageNo, activeTabIndex]);

  useEffect(() => {
    fetchJobsData(null);
    // fetchJobsData();
  }, [pageNo, offset]);

  useEffect(() => {
    fetchLinesData();
  }, [linesPageNo, linesOffset]);

  const updateLinesData = () => {
    fetchLinesData();
  };

  useEffect(() => {
    setFilteredJobs(jobsData);
  }, [jobsData]);

  // useEffect(() => {
  //   setProductCategories([...new Set(linesData?.map((job) => job.ProdGroup))]);
  // }, [linesData]);

  const fetchSupervisorsData = async () => {
    try {
      const response = await axiosPrivate.get(
        `users/supervisors/${userData?.userId}`
      );
      if (response.status === 200) {
        setSupervisors(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSupervisorsData();
  }, []);

  const fetchRequestsData = async () => {
    try {
      const response = await axiosPrivate.get(
        "tracking_stages/get_reopen_requests"
      );
      if (response.status === 200) {
        const plantCodes = userData?.company_and_plants.map((item) =>
          Number(item?.plant_code)
        );
        setRequestsData(
          response.data?.data?.filter((stage) =>
            plantCodes?.includes(Number(stage?.Plant))
          )
        );
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  useEffect(() => {
    fetchRequestsData();
  }, []);

  const updateRequestsData = () => {
    fetchRequestsData();
  };

  // const applyFilters = async () => {
  //   console.log("applying filters");
  //   if (
  //     !filterOrderType?.length &&
  //     !filterOrderNum?.length &&
  //     !filterStatus?.length &&
  //     !filterOrderDate
  //   ) {
  //     return;
  //   }

  //   try {
  //     setIsLoading(true);
  //     const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //     const response = await axiosPrivate.post(
  //       `filter?page=${pageNo}&offset=${offset}`,
  //       {
  //         order_types: filterOrderType,
  //         order_nums: filterOrderNum,
  //         status: filterStatus,
  //         order_date: filterOrderDate
  //           ? new Date(filterOrderDate).toLocaleDateString("en-GB", options)
  //           : null,
  //       }
  //     );
  //     if (response.status === 200) {
  //       const Data = response?.data?.data?.items || [];
  //       const orderNumMap = new Map();
  //       const SoAmountMap = new Map();
  //       const ProdGroupMap = new Map();

  //       Data?.forEach((line) => {
  //         if (SoAmountMap.has(line.OrderNum)) {
  //           SoAmountMap.set(
  //             line.OrderNum,
  //             SoAmountMap.get(line.OrderNum) +
  //             Number(line.IndianAmt.replaceAll(",", ""))
  //           );
  //         } else {
  //           SoAmountMap.set(
  //             line.OrderNum,
  //             Number(line.IndianAmt.replaceAll(",", ""))
  //           );
  //         }
  //       });

  //       Data?.forEach((line) => {
  //         if (ProdGroupMap.has(line?.OrderNum)) {
  //           if (!ProdGroupMap.get(line?.OrderNum)?.includes(line.ProdGroup)) {
  //             ProdGroupMap.set(
  //               line?.OrderNum,
  //               ProdGroupMap.get(line?.OrderNum) + `\n ${line?.ProdGroup}`
  //             );
  //           }
  //         } else {
  //           ProdGroupMap.set(line?.OrderNum, line?.ProdGroup);
  //         }
  //       });

  //       Data?.forEach((line) => {
  //         if (!orderNumMap.has(line.OrderNum)) {
  //           orderNumMap.set(line.OrderNum, {
  //             ...line,
  //             SOTotalAmount: SoAmountMap.get(line.OrderNum)
  //               ? SoAmountMap.get(line.OrderNum)
  //               : "-",
  //             ProdGroupList: ProdGroupMap.get(line.OrderNum)
  //               ? ProdGroupMap.get(line.OrderNum)
  //               : "-",
  //           });
  //         }
  //       });

  //       setTotalJobs(response?.data?.data?.pagination?.totalRecords);
  //       // setTotalLines(response?.data?.count[0]?.pagination?.total_records);

  //       setFilteredJobs(
  //         [...orderNumMap.values()]
  //         //   .sort(
  //         //   (a, b) => b.held_stages - a.held_stages
  //         // )
  //       );

  //       setIsLoading(false);
  //       setFiltersActive(true);
  //     }
  //   } catch (error) {
  //     if (error?.response?.status === 404) {
  //       setIsLoading(false);
  //       setFilteredJobs([]);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  //   // setFilteredJobs(filteredData);
  // };

  const clearFilters = () => {
    setFilterOrderType([]);
    setFilterOrderNum([]);
    setFilterStatus([]);
    setFilterOrderDate(null);
    setFiltersActive(false);
    fetchJobsData(null, true);
  };

  const jobsListData = filteredJobs?.map((value) => ({
    ...value,
    action: (
      <Link
        key={`viewButton_${value.id}`}
        to={`view/${value.OrderNum}`}
        style={{ border: "none", background: "none" }}
        title='View'
      >
        <GrFormView
          size={30}
          color='#ff0000'
        />
      </Link>
    ),
  }));

  const updateProductGroup = async (prodGroup) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `global_search?type=lines&keyword=${prodGroup}`
      );

      if (response.status === 200) {
        setDatabyProdGroup(response?.data?.data);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='content'>
          <div className='card'>
            <TabView
              activeIndex={Number(activeTabIndex)}
              onTabChange={(e) => {
                setActiveTabIndex(e.index);
                // setPageNo(1);
                // setLinesPageNo(1);
              }}
            >
              <TabPanel header='Sales Orders'>
                {/* <div className='col-12 mt-2'> */}
                <div className='d-flex flex-column'>
                  <div
                    className='filter-section col-12 d-flex flex-column rounded-3 p-2'
                    style={{
                      border: "1px solid #dee2e6",
                      backgroundColor: "#f8f9fa",
                    }}
                  >
                    <div className='d-flex flex-wrap justify-content-between align-items-center row mb-2'>
                      <div className='form-group col-md mb-3'>
                        <label className='form-label'>Order Type:</label>
                        <Select
                          options={orderTypes?.map((type) => ({
                            value: type,
                            label: type,
                          }))}
                          isMulti
                          value={filterOrderType?.map((type) => ({
                            value: type,
                            label: type,
                          }))}
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setFilterOrderType(selectedValues);
                          }}
                        />
                      </div>

                      <div className='form-group col-md mb-3'>
                        <label className='form-label'>Sales Order:</label>
                        <Select
                          options={salesOrders?.map((SO) => ({
                            value: SO,
                            label: SO,
                          }))}
                          isMulti
                          value={filterOrderNum?.map((num) => ({
                            value: num,
                            label: num,
                          }))}
                          onChange={(selectedOptions) => {
                            const selectedValues = selectedOptions
                              ? selectedOptions.map((option) => option.value)
                              : [];
                            setFilterOrderNum(selectedValues);
                          }}
                        />
                      </div>

                      <div className='form-group col-md mb-3'>
                        <label className='form-label'>Dispatch Status:</label>
                        <Select
                          options={[
                            {
                              value: 0,
                              label: "Done",
                            },
                            {
                              value: 1,
                              label: "Pending",
                            },
                          ]}
                          isMulti
                          value={filterStatus?.map((value) => ({
                            value: value,
                            label:
                              value === 0
                                ? "Done"
                                : value === 1
                                  ? "Pending"
                                    : "Pending",
                          }))}
                          onChange={(selectedOptions) => {
                            setFilterStatus(
                              selectedOptions
                                ? selectedOptions?.map((option) => option.value)
                                : []
                            );
                          }}
                        />
                      </div>

                      <div className='form-group col-md mb-3'>
                        <label className='form-label'>Order Date:</label>
                        <Calendar
                          value={filterOrderDate}
                          onChange={(e) => setFilterOrderDate(e.value)}
                          dateFormat='dd/mm/yy'
                          showIcon
                          placeholder='DD/MM/YYYY'
                          readOnlyInput
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div className='d-flex justify-content-end align-items-center mt-2 col-auto'>
                        <button
                          className='btn btn-primary'
                          onClick={() => {fetchJobsData(null)}}
                          style={{ marginRight: "10px" }}
                        >
                          Apply
                        </button>
                        <div>
                          <FcClearFilters
                            size={25}
                            onClick={clearFilters}
                            style={{ cursor: "pointer" }}
                            
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <DataTablePrime
                      lists={jobsListData}
                      headers={headers}
                      updateJobsList={updateJobsList}
                      supervisors={supervisors}
                      pageNo={pageNo}
                      offset={offset}
                      setPageNo={setPageNo}
                      setOffset={setOffset}
                      totalJobs={totalJobs}
                      totalLines={totalLines}
                      filtersActive={filtersActive}
                      jobStatusDialog={jobStatusDialog}
                      setJobStatusDialog={setJobStatusDialog}
                      updateReleaseDateDialog={updateReleaseDateDialog}
                      setUpdateReleaseDateDialog={setUpdateReleaseDateDialog}
                      runningStagesDialog={runningStagesDialog}
                      setRunningStagesDialog={setRunningStagesDialog}
                      supervisorId={supervisorId}
                      setSupervisorId={setSupervisorId}
                      orderNumber={orderNumber}
                      setOrderNumber={setOrderNumber}
                      jobStatus={jobStatus}
                      setJobStatus={setJobStatus}
                      fetchJobsData={fetchJobsData}
                      pagination={pagination}
                    />
                  </div>
                </div>
                {/* </div> */}
              </TabPanel>
              <TabPanel header='Production Orders'>
                <TabView
                  activeIndex={activeIndex}
                  onTabChange={(e) => {
                    setActiveIndex(e.index);
                    
                    setSearchParams({ 
                    ...Object.fromEntries([...searchParams]),prodGroup: e?.originalEvent?.target?.innerText,
                    linePage : 1
                    });
                    setLinesPageNo(1);
                    fetchLinesData(null,e?.originalEvent?.target?.innerText, true);
                    
                    
                  }}
                >
                  <TabPanel header='All'>
                    <div className='table-responsive'>
                      <DataTableLines
                        lists={linesData}
                        paginator={true}
                        updateLinesData={updateLinesData}
                        supervisors={supervisors}
                        pageNo={linesPageNo}
                        offset={linesOffset}
                        setPageNo={setLinesPageNo}
                        setOffset={setLinesOffset}
                        totalJobs={totalJobs}
                        totalLines={totalLines}
                        supervisorId={supervisorIdLines}
                        setSupervisorId={setSupervisorIdLines}
                        productionOrder={productionOrder}
                        setProductionOrder={setProductionOrder}
                        runningStages={runningStages}
                        setRunningStages={setRunningStages}
                        releaseDate={releaseDate}
                        setReleaseDate={setReleaseDate}
                        lineStatus={lineStatus}
                        setLineStatus={setLineStatus}
                        isListingPage={true}
                        fetchLinesData={fetchLinesData}
                        linesPagination={linesPagination}
                      />
                    </div>
                  </TabPanel>
                  {productCategories?.map((category, index) => (
                    <TabPanel
                      key={index}
                      header={category}
                    >
                      <div className='table-responsive'>
                        <DataTableLines
                          paginator={true}
                          lists={linesData}
                          updateLinesData={updateLinesData}
                          supervisors={supervisors}
                          pageNo={linesPageNo}
                          offset={linesOffset}
                          setPageNo={setLinesPageNo}
                          setOffset={setLinesOffset}
                          totalJobs={totalJobs}
                          totalLines={totalLines}
                          supervisorId={supervisorIdLines}
                          setSupervisorId={setSupervisorIdLines}
                          productionOrder={productionOrder}
                          setProductionOrder={setProductionOrder}
                          runningStages={runningStages}
                          setRunningStages={setRunningStages}
                          releaseDate={releaseDate}
                          setReleaseDate={setReleaseDate}
                          lineStatus={lineStatus}
                          setLineStatus={setLineStatus}
                          isListingPage={true}
                          fetchLinesData={fetchLinesData}
                          linesPagination={linesPagination}
                        />
                      </div>
                    </TabPanel>
                  ))}
                </TabView>
              </TabPanel>
              {requestsData?.length ? (
                <TabPanel header='Reopen-Requests'>
                  <div className='table-responsive col-12 mb-4'>
                    <DataTableRequests
                      lists={requestsData}
                      updateRequestsData={updateRequestsData}
                    />
                  </div>
                </TabPanel>
              ) : null}
            </TabView>
          </div>
        </div>
      )}
    </>
  );
};

export default JobsList;
