import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import JobCard from "../Jobs/JobCard";
import DataTableSupervisor from "../../components/DataTableSupervisor/DataTableSupervisor";
import "./Dashboard.module.css";
import { TabPanel, TabView } from "primereact/tabview";
import { useState } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import Select from "react-select";
import { FcClearFilters } from "react-icons/fc";
import { useSearchParams } from "react-router-dom";


const Dashboard = () => {
  // Retrieve user data from local storage
  let [searchParams, setSearchParams] = useSearchParams();
  const userData = JSON.parse(localStorage.getItem("userData") || "{}");
  const navigate = useNavigate(); // Hook for programmatic navigation
  const [supervisorData, setSupervisorData] = useState([]);
  const [filterSalesOrder, setFilterSalesOrder] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [offset, setOffset] = useState(20);
  const [linesOffset, setLinesOffset] = useState(20);
  const [pageNo, setPageNo] = useState(() => searchParams.get("page") > 0 ? searchParams.get("page") : 1);
  const [linesPageNo, setLinesPageNo] = useState(() =>
    searchParams.get("linePage") > 0 ? searchParams.get("linePage") : 1
  );
  const [pagination , setPagination] = useState(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeTabIndexStage , setActiveTabIndexStage] = useState("");
  const [activeTabIndexProdGroup , setActiveTabIndexProdGroup] = useState("");

  useEffect(() => {
    // Redirect to login page if userData is not present or essential properties are missing
    if (!userData || !userData.userId || !userData.userName) {
      navigate("/login");
    }
  }, [navigate, userData]);

  useEffect(() => {
    setSearchParams({ page: pageNo, linePage: linesPageNo});
  }, [pageNo, linesPageNo]);


  
  const fetchData = async ( stage_name , prod_group , keyword  , clearFilters) => {
    const payload = {
      type: activeTabIndex === 0 ? "stages" : "prod_group",
      stages_name: stage_name ? stage_name : "",
      prod_group: prod_group ? prod_group : "",
      keyword: keyword,
      status: clearFilters ? null : filterStatus,
      sales_order: clearFilters ? null : filterSalesOrder,
    };
    try {
      const response = await axiosPrivate.post(`dashboard/assigned_stages?page=${pageNo}&offset=${offset}`, payload);
      if (response.status === 200) {       
        setSupervisorData(response?.data?.data);
        // applyFilters(null, response?.data?.data?.data);
        setPagination(response?.data?.data?.pagination);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error.message);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNo,offset,activeTabIndex]);

  // const applyFilters = (e, data = supervisorData) => {
  //   let filteredData = data ;
  

  //   // Apply status filter
  //   if (filterStatus.length > 0) {
  //     filteredData = filteredData?.filter((stage) =>
  //       filterStatus.includes(stage.status)
  //     );
  //   }
  //   // Apply product group filter
  //   if (filterSalesOrder.length > 0) {
  //     filteredData = filteredData?.filter((item) =>
  //       filterSalesOrder.includes(item?.OrderNum)
  //     );
  //   }
    
  //     setFilterData(filteredData);
    
  // };

  // Function to fetch data from API

  const statusOptions = [...new Set(supervisorData.data?.map((stage) => stage?.status))];

  console.log(statusOptions);

  const clearFilters = () => {
    setFilterSalesOrder([]);
    setFilterStatus([]);
      fetchData(null,null,null ,true);
  
    
 
  };

  const updateSuperVisorData = () => {
    fetchData();
  };

  return (
    <>
      <Fragment>
        {/* Main content */}
        <div className='content pt-3 pr-3 pl-3'>
          {/* Section for displaying JobCards */}
          <JobCard data={supervisorData?.data} />
          {/* Additional sections */}
          <div className='container-fluid mt-3'>
            <TabView
            activeIndex={Number(activeTabIndex)}
            onTabChange={(e) => {
            setActiveTabIndex(e.index);
            }}
            >
              {/* Tab for displaying stages */}
              <TabPanel header='Stages'>
                <div className='d-flex justify-content-start align-items-center'>
                  {/* Status filter */}
                  <div className='form-group  col-3'>
                    <label className='form-label'>Status:</label>

                    <Select
                      options={statusOptions?.map((status) => ({
                        value: status,
                        label:
                          status === 0
                            ? "Pending"
                            : status === 1
                            ? "In Progress"
                            : status === 2
                            ? "Hold"
                            : status === 3
                            ? "Resume"
                            : status === 4
                            ? "Completed"
                            : status === 5
                            ? "Re-open"
                            : "Pending",
                      }))}
                      isMulti
                      value={filterStatus?.map((value) => ({
                        value: value,
                        label:
                          value === 0
                            ? "Pending"
                            : value === 1
                            ? "In Progress"
                            : value === 2
                            ? "Hold"
                            : value === 3
                            ? "Resume"
                            : value === 4
                            ? "Completed"
                            : value === 5
                            ? "Re-open"
                            : "Pending",
                      }))}
                      onChange={(selectedOptions) => {
                        setFilterStatus(
                          selectedOptions
                            ? selectedOptions?.map((option) => option.value)
                            : []
                        );
                      }}
                    />
                  </div>

                  <div className='form-group col-3'>
                    <label className='form-label'>Sales Order:</label>
                    <Select
                      options={ supervisorData?.sales_order?.map((orderNum) => ({
                        value: orderNum,
                        label: orderNum,
                      }))}
                      isMulti
                      value={filterSalesOrder?.map((orderNum) => ({
                        value: orderNum,
                        label: orderNum,
                      }))}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions
                          ? selectedOptions?.map((option) => option.value)
                          : [];
                        setFilterSalesOrder(selectedValues);
                      }}
                    />
                  </div>

                  <div className='ml-1 mt-2'>
                    <button
                      className='btn btn-primary ml-1 '
                      onClick={() => fetchData(null , null , null)}
                    >
                      Apply
                    </button>
                  </div>
                  <div className=' ml-1 mt-2'>
                    <FcClearFilters
                      size={25}
                      onClick={clearFilters}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <TabView 
                activeIndex={Number(activeTabIndexStage)}
                onTabChange={(e) => {
                  setActiveTabIndexStage(e.index);
                  fetchData(e?.originalEvent?.target?.innerText , null);
                }
                }
                >
                  <TabPanel header='All'>
                    <DataTableSupervisor
                      lists={supervisorData?.data}
                      updateSuperVisorData={updateSuperVisorData}
                      pagination={pagination}
                      pageNo={pageNo}
                      offset={offset}
                      setPageNo={setPageNo}
                      setOffset={setOffset}
                      fetchData={fetchData}
                    />
                  </TabPanel>
                  {supervisorData?.stages?.map(
                    (stage, index) =>
                      stage && (
                        <TabPanel
                          key={index}
                          header={stage}
                        >
                          <DataTableSupervisor
                            lists={supervisorData?.data}
                            updateSuperVisorData={updateSuperVisorData}
                            pagination={
                              pagination
                            }
                            pageNo={pageNo}
                            offset={offset}
                            setPageNo={setPageNo}
                            setOffset={setOffset}
                            fetchData={fetchData}
                          />
                        </TabPanel>
                      )
                  )}
                </TabView>
              </TabPanel>

              {/* Tab for displaying product categories */}
              <TabPanel header='Product Groups'>
                <div className='d-flex'>
                  <div className='form-group col-3'>
                    <label className='form-label mt-2'>Sales Order:</label>
                    <Select
                      options={supervisorData?.sales_order?.map((orderNum) => ({
                        value: orderNum,
                        label: orderNum,
                      }))}
                      isMulti
                      value={filterSalesOrder?.map((orderNum) => ({
                        value: orderNum,
                        label: orderNum,
                      }))}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions
                          ? selectedOptions?.map((option) => option.value)
                          : [];
                        setFilterSalesOrder(selectedValues);
                      }}
                    />
                  </div>
                  <div className=' d-flex form-group col-2 mt-3'>
                    <div>
                      <button
                        className='btn btn-primary ml-1 mt-4'
                        onClick={fetchData}
                      >
                        Apply
                      </button>
                    </div>
                    <div className=' ml-1 mt-4'>
                      <FcClearFilters
                        size={25}
                        onClick={clearFilters}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
                <TabView
                activeIndex={Number(activeTabIndexProdGroup)}
                onTabChange={(e) => {
                 setActiveTabIndexProdGroup(e.index)
                  fetchData(null , e?.originalEvent?.target?.innerText);
                }}
                >
                  {/* Tab for displaying all product categories */}

                  <TabPanel header='All'>
                    <DataTableSupervisor
                      lists={supervisorData?.data}
                      updateSuperVisorData={updateSuperVisorData}
                      pageNo={pageNo}
                      offset={offset}
                      setPageNo={setPageNo}
                      setOffset={setOffset}
                      pagination={pagination}
                      fetchData={fetchData}
                    />
                  </TabPanel>

                  {/* Tab for displaying product categories individually */}
                  {supervisorData?.prod_grp?.map(
                    (prod_group, index) =>
                      prod_group && (
                        <TabPanel
                          key={index}
                          header={prod_group}
                        >
                          <div className='table-responsive col-12 mb-4'>
                            <DataTableSupervisor
                              lists={supervisorData?.data}
                              updateSuperVisorData={updateSuperVisorData}
                              pagination={pagination}
                              pageNo={pageNo}
                              offset={offset}
                              setPageNo={setPageNo}
                              setOffset={setOffset}
                              fetchData={fetchData}
                            />
                          </div>
                        </TabPanel>
                      )
                  )}
                </TabView>
              </TabPanel>
            </TabView>
            <h4 className='mt-5'>Help</h4>
            <div className='row d-flex mt-3'>
              <div
                className='col-md-6 col-lg-4 mb-3 
                '
              >
                <div className='card help'>
                  <div className='card-body'>
                    <td
                      valign='middle'
                      style={{ paddingRight: "19px" }}
                    >
                      <button
                        className='iconBtn start'
                        data-bs-toggle='modal'
                        data-bs-target='#confirmModal'
                      >
                        <svg
                          width='15'
                          height='3'
                          viewBox='0 0 15 3'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <circle
                            cx='1.5'
                            cy='1.5'
                            r='1.5'
                            fill='white'
                          />
                          <circle
                            cx='7.5'
                            cy='1.5'
                            r='1.5'
                            fill='white'
                          />
                          <circle
                            cx='13.5'
                            cy='1.5'
                            r='1.5'
                            fill='white'
                          />
                        </svg>
                      </button>
                    </td>
                    <td>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        This sign indicates that this stage has not been
                        initiated yet.
                      </p>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        {" "}
                        यह संकेत दिखाता है कि चरण अभी तक शुरू नहीं हुआ है।
                      </p>
                    </td>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-3'>
                <div className='card help'>
                  <div className='card-body'>
                    <td
                      valign='middle'
                      style={{ paddingRight: "19px" }}
                    >
                      <button
                        className='iconBtn hold'
                        data-bs-toggle='modal'
                        data-bs-target='#confirmModal'
                      >
                        <svg
                          width='10'
                          height='12'
                          viewBox='0 0 10 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M9.10303 4.70089C10.103 5.27824 10.103 6.72161 9.10303 7.29896L2.5736 11.0687C1.5736 11.6461 0.3236 10.9244 0.3236 9.76969L0.323601 2.23016C0.323601 1.07546 1.5736 0.353769 2.5736 0.931119L9.10303 4.70089Z'
                            fill='white'
                          />
                        </svg>
                      </button>
                    </td>
                    <td>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        To resume the stage,please click on the designated
                        button.
                      </p>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        चरण को फिर से शुरू करने के लिए, कृपया निर्दिष्ट बटन पर
                        क्लिक करें।
                      </p>
                    </td>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-3'>
                <div className='card help'>
                  <div className='card-body'>
                    <td
                      valign='middle'
                      style={{ paddingRight: "19px" }}
                    >
                      <button
                        className='iconBtn complete'
                        data-bs-toggle='modal'
                        data-bs-target='#confirmModal'
                      >
                        <svg
                          width='12'
                          height='9'
                          viewBox='0 0 12 9'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1 4L4.5 7.5L10.5 1.5'
                            stroke='white'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </td>
                    <td>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        Once the stage is completed to close the stage,please
                        click on the designated button.
                      </p>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        जब चरण पूरा हो जाए, तो चरण को बंद करने के लिए, कृपया
                        निर्दिष्ट बटन पर क्लिक करें।
                      </p>
                    </td>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-3'>
                <div className='card help'>
                  <div className='card-body'>
                    <td
                      valign='middle'
                      style={{ paddingRight: "19px" }}
                    >
                      <button
                        className='iconBtn hold'
                        data-bs-toggle='modal'
                        data-bs-target='#confirmModal'
                      >
                        <svg
                          width='8'
                          height='10'
                          viewBox='0 0 8 10'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M1 0.5C0.447715 0.5 0 0.947715 0 1.5V8.5C0 9.05229 0.447715 9.5 1 9.5H2C2.55228 9.5 3 9.05229 3 8.5V1.5C3 0.947715 2.55228 0.5 2 0.5H1ZM6 0.5C5.44772 0.5 5 0.947715 5 1.5V8.5C5 9.05229 5.44772 9.5 6 9.5H7C7.55228 9.5 8 9.05229 8 8.5V1.5C8 0.947715 7.55228 0.5 7 0.5H6Z'
                            fill='white'
                          />
                        </svg>
                      </button>
                    </td>
                    <td>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        To hold the stage,please click on the designated button.
                      </p>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        चरण को रोकने के लिए, कृपया निर्दिष्ट बटन पर क्लिक करें।
                      </p>
                    </td>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-3'>
                <div className='card help'>
                  <div className='card-body'>
                    <td
                      valign='middle'
                      style={{ paddingRight: "19px" }}
                    >
                      <button
                        className='iconBtn reopen'
                        data-bs-toggle='modal'
                        data-bs-target='#confirmModal'
                      >
                        <svg
                          width='13'
                          height='13'
                          viewBox='0 0 13 13'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M0 5.68743C0 6.13688 0.363086 6.5 0.8125 6.5C1.26191 6.5 1.625 6.13688 1.625 5.68743C1.625 4.34161 2.7168 3.24972 4.0625 3.24972H8.125V4.06229C8.125 4.38986 8.32305 4.68695 8.62773 4.81392C8.93242 4.94088 9.28027 4.86978 9.51387 4.63871L11.1389 3.01356C11.4563 2.69615 11.4563 2.18068 11.1389 1.86327L9.51387 0.238128C9.28027 0.00451427 8.93242 -0.0640464 8.62773 0.0629178C8.32305 0.189882 8.125 0.484439 8.125 0.812006V1.62458H4.0625C1.81797 1.62458 0 3.4427 0 5.68743ZM13 7.31257C13 6.86312 12.6369 6.5 12.1875 6.5C11.7381 6.5 11.375 6.86312 11.375 7.31257C11.375 8.65839 10.2832 9.75028 8.9375 9.75028H4.875V8.93771C4.875 8.61014 4.67695 8.31305 4.37227 8.18608C4.06758 8.05912 3.71973 8.13022 3.48613 8.36129L1.86113 9.98643C1.54375 10.3038 1.54375 10.8193 1.86113 11.1367L3.48613 12.7619C3.71973 12.9955 4.06758 13.064 4.37227 12.9371C4.67695 12.8101 4.875 12.5156 4.875 12.1855V11.3754H8.9375C11.182 11.3754 13 9.5573 13 7.31257Z'
                            fill='white'
                          />
                        </svg>
                      </button>
                    </td>
                    <td>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        To reopen the stage,please click on the designated
                        button.
                      </p>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        चरण को पुनः खोलने के लिए, कृपया निर्दिष्ट बटन पर क्लिक
                        करें।
                      </p>
                    </td>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-4 mb-3'>
                <div className='card help'>
                  <div className='card-body'>
                    <td
                      valign='middle'
                      style={{ paddingRight: "19px" }}
                    >
                      <button
                        className='iconBtn remark'
                        data-bs-toggle='modal'
                        data-bs-target='#confirmModal'
                      >
                        <svg
                          width='14'
                          height='12'
                          viewBox='0 0 14 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M14 5.57018C14 8.64718 10.8667 11.1404 7.00071 11.1404V11.143C5.98636 11.143 5.02396 10.9717 4.15451 10.6637C3.82916 10.8967 3.29601 11.2154 2.6699 11.4832C2.01372 11.7617 1.22356 12 0.438876 12C0.26116 12 0.102582 11.8956 0.0342298 11.7349C-0.0341229 11.5742 0.00142051 11.3921 0.127189 11.2689L0.135391 11.2609C0.138786 11.2576 0.14265 11.2533 0.147176 11.2483C0.153584 11.2413 0.161321 11.2328 0.170935 11.2234C0.203743 11.1886 0.247489 11.1377 0.304905 11.0707C0.417003 10.9368 0.570113 10.7387 0.720488 10.4923C0.993898 10.0478 1.25364 9.46396 1.30558 8.80785C0.485356 7.89467 0.00142051 6.77795 0.00142051 5.57018C0.00142051 2.49319 3.1347 0 7.00071 0C10.8667 0 14 2.49319 14 5.57018ZM6.05556 5.52778C6.05556 6.50962 5.25962 7.30556 4.27778 7.30556C3.29594 7.30556 2.5 6.50962 2.5 5.52778C2.5 4.54594 3.29594 3.75 4.27778 3.75C5.25962 3.75 6.05556 4.54594 6.05556 5.52778ZM11.4999 5.52778C11.4999 6.50962 10.704 7.30556 9.72211 7.30556C8.74027 7.30556 7.94434 6.50962 7.94434 5.52778C7.94434 4.54594 8.74027 3.75 9.72211 3.75C10.704 3.75 11.4999 4.54594 11.4999 5.52778Z'
                            fill='white'
                          />
                        </svg>
                      </button>
                    </td>
                    <td>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        To add remarks for the stage,please click on the
                        designated button.
                      </p>
                      <p
                        className='m-0'
                        style={{ display: "block" }}
                      >
                        चरण के लिए टिप्पणियाँ जोड़ने के लिए, कृपया निर्दिष्ट बटन
                        पर क्लिक करें।
                      </p>
                    </td>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default Dashboard;
